<template>
  <div class="tarot">
    <v-container>
      <div class="slider">
        <div class="slider-inner" style="width: 500px; height: 500px">
          <div
            class="slider-origin"
            style="
              width: 50px;
              height: 50px;
              margin-top: 225px;
              margin-left: 225px;
            "
          >
            <div
              v-for="(item, index) in cards"
              :key="index"
              @click="selectCard(item, index)"
            >
              <TarotCard
                class="slider-item"
                :text="item.text"
                :deg="item.deg"
              />
            </div>
          </div>
        </div>
      </div>

      <v-btn @click="loadData()">Create Cards</v-btn>
    </v-container>
  </div>
</template>

<script >
import Tarot from "./tarot";
export default Tarot;
</script>

<style >
.slider {
  padding: 0px 0;
}
.slider-origin {
  background-color: #bd2cbd;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.slider-inner {
  display: inline-block;
}
.slider-item {
  width: 74px;
  height: 100px;
  background-color: rgba(128, 0, 128, 0.4);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: absolute;
  bottom: 350%;
  left: 50%;
  margin-left: -37px;
  cursor: pointer;
  transform-origin: center 175%;
  -webkit-transform-origin: center 250%;
  -ms-transform-origin: center 175%;
  -moz-transform-origin: center 175%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  text-align: left;
  color: #fff;
  padding: 7px;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slider-item.active {
  background-color: purple;
  cursor: default;
}
.slider-item:not(.active):hover {
  background-color: #540554;
}
</style>
