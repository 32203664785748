




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TarotCard extends Vue {
  @Prop() private text!: string;
  @Prop() private deg!: number;
}
