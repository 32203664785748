import Vue from "vue";
import { Component } from "vue-property-decorator";
import TarotCard from "../../components/TarotCard.vue";

@Component({
  components: {
    TarotCard
  }
})
export default class Tarot extends Vue {

  cards: any = [];
  currentSelectCardItem = {
    index: -1,
    srcDeg: 0
  };


  getCardsData() {
    const result: any = [];
    const cardsNumber = 32;
    const intDeg = 25;
    const avgDeg = (360 - (intDeg * 2)) / (cardsNumber - 1);
    for (let i = 0; i < cardsNumber; i++) {
      result.push({
        text: i,
        deg: 0
      });
    }

    this.cards = result;

    let count = 0
    const timer = setInterval(function () {
      if (count >= cardsNumber) {
        clearInterval(timer);
      } else {
        result[count].deg = intDeg + (count * avgDeg);
        count++;
      }

    }, 50);

  }

  loadData() {
    this.getCardsData();
  }

  selectCard(item: any, index: number) {
    if (this.currentSelectCardItem.index != -1) {
      this.cards[this.currentSelectCardItem.index].deg = this.currentSelectCardItem.srcDeg;
    }
    this.currentSelectCardItem.index = index;
    this.currentSelectCardItem.srcDeg = item.deg;
    if (item.deg > 180) {
      item.deg = 360;
    } else {
      item.deg = 0;
    }
  }

}